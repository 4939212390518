@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;600;700&display=swap");

p {
  margin: 0;
  font-family: Arial;
  /* //"Work Sans"; */
  font-weight: 300;
}

.li-dashboard {
  margin: 0;
  font-family: Arial;
  /* "Work Sans"; */
  font-weight: 300;
  font-size: 15px;
}

.thumbs {
  padding: 0;
}

.thumb {
  height: 30 !important;
  width: 30 !important;
}

.thumbs-wrapper {
  margin: 0 !important;
}

.link-under-thumbnail:hover {
  text-decoration: underline;
}
.description-project h2 {
  margin-bottom: 2px;
}
.description-project h2:first-child {
  margin-top: 0;
}
.description-project ul {
  /* background-color: blue; */
  margin-top: 0;
}
center nav {
  margin: auto;
}
